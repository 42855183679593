import { Outlet } from 'react-router-dom';
import {  useEffect } from 'react';
import useScrollToTop from 'src/Routers/ScrollToTop';
import useModalManager from "src/hooks/useModalManager";
import { useCurrentUser } from 'src/hooks/useCurrentUser';

const Layout = () => {
  const { user } = useCurrentUser();
  const { setIsLoginOpen } = useModalManager();

  useScrollToTop();

  useEffect(() => {
    if (user?.status === "CHANGED") {
      setIsLoginOpen(true);
    }
  }, [user?.status, setIsLoginOpen]);

  return (
    <Outlet />
  );
};

export default Layout;
