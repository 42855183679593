import axios from "axios";
import { constants } from "src/constant";

const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_API_URL });

API.interceptors.request.use((req) => {
  // check if there is any guest session
  const accessToken =
    sessionStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY) ||
    localStorage.getItem(constants.ACCESS_TOKEN_STORAGE_KEY);

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }

  return req;
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (!originalConfig.url.includes("login") && err.response) {
      // Access Token was expired
      if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
        originalConfig._retry = true;

        const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN_STORAGE_KEY);

        try {
          if (refreshToken) {
            localStorage.setItem(constants.ACCESS_TOKEN_STORAGE_KEY, refreshToken);
          }

          return API(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default API;
