import React from "react";
import ReactDOM from "react-dom/client";

import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";

import "./assets/css/App.css";
import "./assets/css/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "./components/ui/sonner";
import { UserProvider } from "./hooks/useCurrentUser";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <Toaster position="top-center" />
        <App />
      </UserProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
