import { createSelectors, useModalManagerStore } from "src/lib/store";

const useModalManager = () => {
  const modalManagerStore = createSelectors(useModalManagerStore);

  const isSignUpOpen = modalManagerStore.use.isSignUpOpen();
  const isLoginOpen = modalManagerStore.use.isLoginOpen();
  const isOTPOpen = modalManagerStore.use.isOTPOpen();
  const isChatOpen = modalManagerStore.use.isChatOpen();
  const isHandymanProfileOpen = modalManagerStore.use.isHandymanProfileOpen();
  const isForgotPasswordOpen = modalManagerStore.use.isForgotPasswordOpen();
  const isSignUpPostIssueOpen = modalManagerStore.use.isSignUpPostIssueOpen();
  const isDialogOpen = modalManagerStore.use.isQRDialogOpen();
  const isResetPasswordOpen = modalManagerStore.use.isResetPasswordOpen();
  const isContactDialogOpen = modalManagerStore.use.isContactDialogOpen();
  const setIsOTPOpen = modalManagerStore.use.setIsOTPOpen();
  const setIsSignUpOpen = modalManagerStore.use.setIsSignUpOpen();
  const setIsLoginOpen = modalManagerStore.use.setIsLoginOpen();
  const setIsSignUpPostIssueOpen = modalManagerStore.use.setIsSignUpPostIssueOpen();
  const setIsForgotPasswordOpen = modalManagerStore.use.setIsForgotPasswordOpen();
  const setIsResetPasswordOpen = modalManagerStore.use.setIsResetPasswordOpen();
  const setIsChatOpen = modalManagerStore.use.setIsChatOpen();
  const setIsHandymanProfileOpen = modalManagerStore.use.setIsHandymanProfileOpen();
  const setIsDialogOpen = modalManagerStore.use.setIsQRDialogOpen();
  const setIsContactDialogOpen = modalManagerStore.use.setIsContactDialogOpen();
  const closeAllModal = modalManagerStore.use.closeModal();

  return {
    isSignUpOpen,
    isLoginOpen,
    isForgotPasswordOpen,
    isSignUpPostIssueOpen,
    isResetPasswordOpen,
    isContactDialogOpen,
    setIsSignUpOpen,
    setIsLoginOpen,
    setIsSignUpPostIssueOpen,
    setIsForgotPasswordOpen,
    setIsResetPasswordOpen,
    isOTPOpen,
    setIsOTPOpen,
    isChatOpen,
    isHandymanProfileOpen,
    setIsHandymanProfileOpen,
    setIsChatOpen,
    isDialogOpen,
    setIsDialogOpen,
    setIsContactDialogOpen,
    closeAllModal,
  };
};

export default useModalManager;
