interface RoutesConfig {
  Landing: string;
  Partner: string;
  Contact: string;
  Profile: string;
  WaitingList: string;
  Booking: string;
  Browse: string;
  RaiseIssue: string;
  Message: string;
}

export const RoutePaths: RoutesConfig = {
  Landing: "/",
  Partner: "/become-a-partner",
  Contact: "/contact-us",
  Profile: "/profile",
  WaitingList: "/waiting-list",
  Booking: "/booking",
  Browse: "/browse",
  RaiseIssue: "/raise-issue",
  Message: "/message",
};
