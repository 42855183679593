import { create } from "zustand";
import { StoreApi, UseBoundStore } from "zustand";
import { BookingListTypeData } from "src/pages/Booking/BookingList/api/types";

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

// Reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/auto-generating-selectors.md
export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  let store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

interface ResetPasswordFlowState {
  userToReset: { email: string | null; phoneNumber: string | null } | null;
  setUserToReset: (user: { email: string | null; phoneNumber: string | null }) => void;
}
interface SignupFlowState {
  savedUser: {
    email: string | null;
    phoneNumber: string | null;
    password: string | null;
    previousStep: "FORGOT" | "SIGNUP" | null;
  } | null;
  setSavedUser: (user: {
    email: string | null;
    phoneNumber: string | null;
    password: string | null;
    previousStep: "FORGOT" | "SIGNUP" | null;
  }) => void;
}

interface GuestBookingDetailState {
  booking: BookingListTypeData | null;
  setBooking: (booking: BookingListTypeData | null) => void;
}

interface ModalManagerState {
  isForgotPasswordOpen: boolean;
  isResetPasswordOpen: boolean;
  isSignUpPostIssueOpen: boolean;
  isSignUpOpen: boolean;
  isLoginOpen: boolean;
  isOTPOpen: boolean;
  isChatOpen: boolean;
  isHandymanProfileOpen: boolean;
  isQRDialogOpen: boolean;
  isContactDialogOpen: boolean;
  setIsForgotPasswordOpen: (open: boolean) => void;
  setIsSignUpPostIssueOpen: (open: boolean) => void;
  setIsSignUpOpen: (open: boolean) => void;
  setIsLoginOpen: (open: boolean) => void;
  setIsResetPasswordOpen: (open: boolean) => void;
  setIsOTPOpen: (open: boolean) => void;
  setIsChatOpen: (open: boolean) => void;
  setIsHandymanProfileOpen: (open: boolean) => void;
  setIsQRDialogOpen: (open: boolean) => void;
  setIsContactDialogOpen: (open: boolean) => void;
  closeModal: () => void;
}

const modalManagerDefaultValues = {
  isForgotPasswordOpen: false,
  isResetPasswordOpen: false,
  isSignUpPostIssueOpen: true,
  isSignUpOpen: false,
  isLoginOpen: false,
  isOTPOpen: false,
  isChatOpen: false,
  isHandymanProfileOpen: false,
  isQRDialogOpen: false,
  isContactDialogOpen: false,
} as const;

export const useModalManagerStore = create<ModalManagerState>()((set) => ({
  ...modalManagerDefaultValues,
  // when opening one modal, close all other modals because there can only be one open modal
  setIsForgotPasswordOpen: (val) =>
    set(() => ({ ...modalManagerDefaultValues, isForgotPasswordOpen: val })),
  setIsResetPasswordOpen: (val) =>
    set(() => ({ ...modalManagerDefaultValues, isResetPasswordOpen: val })),
  setIsSignUpPostIssueOpen: (val) =>
    set(() => ({ ...modalManagerDefaultValues, isSignUpPostIssueOpen: val })),
  setIsLoginOpen: (val) => set(() => ({ ...modalManagerDefaultValues, isLoginOpen: val })),
  setIsSignUpOpen: (val) => set(() => ({ ...modalManagerDefaultValues, isSignUpOpen: val })),
  setIsOTPOpen: (val) => set(() => ({ ...modalManagerDefaultValues, isOTPOpen: val })),
  setIsChatOpen: (val) => set(() => ({ ...modalManagerDefaultValues, isChatOpen: val })),
  setIsHandymanProfileOpen: (val) =>
    set(() => ({ ...modalManagerDefaultValues, isHandymanProfileOpen: val })),
  setIsQRDialogOpen: (val) => set(() => ({ ...modalManagerDefaultValues, isQRDialogOpen: val })),
  setIsContactDialogOpen: (val) =>
    set(() => ({ ...modalManagerDefaultValues, isContactDialogOpen: val })),
  closeModal: () => set(() => ({ ...modalManagerDefaultValues })),
}));

export const useResetPasswordFlow = create<ResetPasswordFlowState>()((set) => ({
  userToReset: null,
  setUserToReset: (user) => set(() => ({ userToReset: user })),
}));

export const useSignupFlow = create<SignupFlowState>()((set) => ({
  savedUser: null,
  setSavedUser: (user) => set(() => ({ savedUser: user })),
}));

export const useGuestBookingDetailFlow = create<GuestBookingDetailState>()((set) => ({
  booking: null,
  setBooking: (booking) => set(() => ({ booking })),
}))