import { createContext, useContext } from "react";
import { useGetCurrentUser } from "src/components/Auth/LogIn/api/queries";
import { CurrentUser } from "src/services/Auth.service";

type UserContextType = {
  user: CurrentUser | null;
  isLoading: boolean;
  isGuest: boolean;
};

const UserContext = createContext<UserContextType>({
  user: null,
  isLoading: false,
  isGuest: false,
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const userQuery = useGetCurrentUser();

  const isGuest = userQuery.data?.role === "GUEST";

  return (
    <UserContext.Provider
      value={{ user: userQuery.data ?? null, isLoading: userQuery.isLoading, isGuest }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const currentUser = useContext(UserContext);
  if (!currentUser) {
    throw new Error("useCurrentUser must be used inside a UserProvider");
  }

  return currentUser;
};
