import { lazy, Suspense } from "react";
import {
  Route,
  Navigate,
  createRoutesFromElements,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { RoutePaths } from "./routePaths";
import { CgSpinner } from "react-icons/cg";
import Layout from "./Layout";

import "react-international-phone/style.css";
import "react-phone-number-input/style.css";

const WaitingList = lazy(() => import("./Landing/WaitingList"));

let routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path={RoutePaths.Landing} element={<WaitingList />} />
      <Route path="*" element={<Navigate to={RoutePaths.Landing} />} />
    </Route>,
  ),
);

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-full items-center justify-center py-48">
          <CgSpinner className="text-primary-400 size-20 animate-spin" />
        </div>
      }
    >
      <RouterProvider router={routes} />
    </Suspense>
  );
};

export default App;
